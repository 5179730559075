<template>
  <div class="big-modal modal sign-modal">
    <div class="overlay"  @click="$parent.closeSignInModal"></div>
    <div class="wrapper">
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <div class="form-fields">
              <video loop autoplay muted>
                <source src="./../videos/hero.mp4" type="video/mp4">
              </video>
              <div class="left">
                <div class="left-wrapper">
                  <router-link to="/" class="logo">
                    <img src="./../img/logo.svg" class="img"/>
                  </router-link>
                  <a @click="$parent.closeSignInModal" class="desc back">Back</a>
                  <div class="title color">
                    <b>Buy Bitcoin with Confidence.</b>
                  </div>
                  <div class="main-desc desc">
                    If you want to buy cryptocurrency quickly and easily, your best option is Verator.
                  </div>
                </div>
              </div>
              <div class="right-container">
                <div class="right">
                  <div class="right-wrapper">
                    <div class="form-fields-wrapper">
                      <div class="title">Welcome back!</div>
                      <div class="desc">Login using your Email & Password</div>
                      <label>
                        <div class="desc">E-mail address:</div>
                        <input type="email" v-model="email"/>
                      </label>
                      <label>
                        <div class="desc">Password:</div>
                        <input type="password" v-model="pass"/>
                      </label>
                      <div class="link-container">
                        <div class="desc link" @click="$parent.openRecover">Forgot password?</div>
                      </div>
                      <button class="button color" @click="submitLogin">
                        <span>Sign in</span>
                      </button>
                      <div v-if="$parent.error" class="desc red">{{$parent.error}}</div>
                      <div class="bottom-link">
                        <div class="desc">Don't have an account?</div>
                        <div class="desc link" @click="$parent.openRegister">Sign Up!</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SignInModal',
  props: [],
  components: {
  },
  data: function() {
    return {
      email: '',
      pass: ''
      // remember: false
    }
  },
  // watch: {
  //   rememberMe(value) {
  //     localStorage.setItem('remember', JSON.stringify(value));
  //   }
  // },
  // mounted() {
  //   const remember = localStorage.getItem('remember');
  //   if (remember) {
  //     this.remember = JSON.parse(remember);
  //   }
  // },
  computed: {
    
  },
  methods: {
    submitLogin() {
      let loginData = {
        "username": this.email,
        "password": this.pass
      }

      this.$emit('login', loginData)
    }
  }
}
</script>