<template>
  <main class="main profile-page"> 
    <div class="section hero-section">
      <video loop autoplay muted>
        <source src="./../videos/hero.mp4" type="video/mp4">
      </video>
      <div class="wrapper">
        <div class="title color">
          <b>Profile</b>
        </div>
      </div>
    </div>
    <div class="section profile-section">
      <div class="wrapper">
        <div class="flex">
          <div class="card card-1">
            <div class="avatar-container">
              <div class="hover-link">
                <img @click="$parent.openEditDataModal" src="./../img/pen.svg" class="img"/>
              </div>
              <img src="./../img/user.svg" class="user"/>
              <div class="hover-link">
                <img @click="$parent.openPassDataModal" src="./../img/key.svg" class="img"/>
              </div>
            </div>
            <div class="desc big bold">
              {{ $parent.profileData.name }} {{ $parent.profileData.surname }}
            </div>
            <div class="input-container">
              <div class="desc">
                <b>{{ $parent.profileData.email }}</b>
              </div>
              <div v-if="$parent.emailVerified" class="verified">
                <img src="./../img/verified.svg" class="img"/>
                <div class="desc">verified</div>
              </div>
            </div>
            <div v-if="$parent.profileData.status !== 'approved'" id="sumsub-websdk-container"></div>
          </div>
          <div class="card card-2">
            <img src="./../img/shield.svg" class="img big"/>
            <div class="desc big bold">Corporate Verification</div>
            <router-link to="/" class="button">Ask Verification</router-link>
            <div class="desc big bold">Two-factor authentication</div>
            <div class="input-container">
              <img src="./../img/shield_x_icon.svg" class="img"/>
              <div class="desc">YOUR ACCOUNT IS NOT SECURE</div>
            </div>
            <div class="desc small">
              Use Google Authenticator for 2-factor account security. By default, we will always send emails regarding sensitive account actions.
            </div>
            <div class="google">
              <div class="desc">Google Authenticator</div>
              <img src="./../img/shield_check_icon.svg" class="img"/>
              <div class="desc small">
                <b>ENABLE 2FA</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import snsWebSdk from '@sumsub/websdk';

export default {
  name: 'Profile',
  props: [],
  components: {
  },
  data: function() {
    return {
      curPass: '',
      newPass: '',
      repeatPass: '',
      email: '',
      name: '',
      surname: '',
      phone: '',
      profileUpdateSuccessMessaage: '',
      profileUpdateErrorMessaage: '',
      passUpdateSuccessMessaage: '',
      passUpdateErrorMessaage: '',
      token: '',
      status: ''
    }
  },
  watch: {
    
  },
  mounted() {
    this.$http.get(process.env.VUE_APP_API + 'sumsub-token')
    .then((res) => {
      
      
        this.token = res.data.token;
       this.launchWebSdk(this.token, this.email, this.phone)
    })
    .catch(() => {
      
    })
  },
  methods: {
    launchWebSdk(accessToken, applicantEmail, applicantPhone) {
      let snsWebSdkInstance = snsWebSdk.init(
              accessToken,
              // token update callback, must return Promise
              // Access token expired
              // get a new one and pass it to the callback to re-initiate the WebSDK
              () => this.getNewAccessToken()
          )
          .withConf({
              levelName: 'Without KYC',
              lang: 'en', //language of WebSDK texts and comments (ISO 639-1 format)
              email: applicantEmail,
              phone: applicantPhone,
              i18n: {"document":{"subTitles":{"IDENTITY": "Upload a document that proves your identity"}}},
              uiConf: {
                  customCssStr: ":root {\n  --black: #000000;\n   --grey: #F5F5F5;\n  --grey-darker: #B2B2B2;\n  --border-color: #DBDBDB;\n}\n\np {\n  color: var(--black);\n  font-size: 16px;\n  line-height: 24px;\n}\n\nsection {\n  margin: 40px auto;\n}\n\ninput {\n  color: var(--black);\n  font-weight: 600;\n  outline: none;\n}\n\nsection.content {\n  background-color: var(--grey);\n  color: var(--black);\n  padding: 40px 40px 16px;\n  box-shadow: none;\n  border-radius: 6px;\n}\n\nbutton.submit,\nbutton.back {\n  text-transform: capitalize;\n  border-radius: 6px;\n  height: 48px;\n  padding: 0 30px;\n  font-size: 16px;\n  background-image: none !important;\n  transform: none !important;\n  box-shadow: none !important;\n  transition: all 0.2s linear;\n}\n\nbutton.submit {\n  min-width: 132px;\n  background: none;\n  background-color: var(--black);\n}\n\n.round-icon {\n  background-color: var(--black) !important;\n  background-image: none !important;\n}"
              }
          })
          .withOptions({ addViewportTag: false, adaptIframeHeight: true})
          // see below what kind of messages WebSDK generates
          .on('idCheck.stepCompleted', (payload) => {
              console.log('stepCompleted', payload)
          })
          .on('idCheck.onError', (error) => {
              console.log('onError', error)
          })
          .build();

      // you are ready to go:
      // just launch the WebSDK by providing the container element for it
      snsWebSdkInstance.launch('#sumsub-websdk-container')
    },
    getNewAccessToken() {
      return Promise.resolve(this.token)// get a new token from your backend
    },
    setAppAmounts(amounts) {
      this.$emit('setAppAmounts',  amounts)
    },
    updateProfileData() {
      let profileData = {
        "email": this.email,
        "name": this.name,
        "surname": this.surname,
      }
      this.$http.put(process.env.VUE_APP_API + 'user/profile', profileData)
      .then((res) => {
        if (res.data.status == "OK") {
          this.profileUpdateSuccessMessaage = 'Success';
          setTimeout(() => {
            this.profileUpdateSuccessMessaage = '';
          }, 3000);
        }
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
        this.profileUpdateErrorMessaage = res.response.data.message;
        setTimeout(() => {
          this.profileUpdateErrorMessaage = '';
        }, 3000);
      })
    },
    updatePassword() {
      let passwordList = {
        "cur_password": this.curPass,
        "new_password": this.newPass,
        "repeat_password": this.repeatPass
      }
      this.$http.post(process.env.VUE_APP_API + 'user/change-password', passwordList)
      .then((res) => {
        if (res.data.status == "OK") {
          this.passUpdateSuccessMessaage = 'Success';
          this.curPass = '',
          this.newPass = '',
          this.repeatPass = '';
          setTimeout(() => {
            this.passUpdateSuccessMessaage = '';
          }, 3000);
        }
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
        this.passUpdateErrorMessaage = res.response.data.message;
        setTimeout(() => {
          this.passUpdateErrorMessaage = '';
        }, 3000);
      })
    },
  }
}
</script>