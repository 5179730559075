<template>
  <div class="modal register-modal edit-form">
    <div class="overlay"  @click="$parent.closeEditDataModal"></div>
    <div class="wrapper">
      <img class="close" @click="$parent.closeEditDataModal" src="./../img/close.svg"/>
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <div class="form-fields">
              <div class="form-fields-wrapper">
                <div class="title">Edit personal data</div>
                <div class="label-list">
                  <label>
                    <div class="desc">Name</div>
                    <input type="text" v-model="name"/>
                  </label>
                  <label>
                    <div class="desc">Surname</div>
                    <input type="text" v-model="surname"/>
                  </label>
                  <label>
                    <div class="desc">E-Mail Address</div>
                    <input type="email" v-model="email"/>
                  </label>
                  <label>
                    <div class="desc">Phone</div>
                    <VuePhoneNumberInput v-model="phone" :only-countries="$parent.countryOptionsIso" :fetch-country="true" @update="phoneDataUpdate($event)"/>
                  </label>
                  <label>
                    <div class="desc">City</div>
                    <input type="text" v-model="city"/>
                  </label>
                  <label>
                    <div class="desc">Address</div>
                    <input type="text" v-model="address"/>
                  </label>
                  <label>
                    <div class="desc">Post code</div>
                    <input type="text" v-model="postCode"/>
                  </label>
                  <label>
                    <div class="desc">Country</div>
                    <select v-model="country">
                      <option disabled selected value="">Country</option>
                      <option v-for="(item, i) in $parent.countryOptions" :value="item" :key="i">{{ item.title }}</option>
                    </select>
                  </label>
                </div>
                <div class="button-container">
                  <button :class="['button color', {'disabled': !requiredFieldsAreFilled}]" @click="submitEditData">
                    <span>Submit</span>
                  </button>
                </div>
                <div v-if="$parent.successMessage" class="desc green">{{$parent.successMessage}}</div>
                <div v-if="$parent.error" class="desc red">{{$parent.error}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
Vue.component('vue-phone-number-input', VuePhoneNumberInput);

export default {
  name: 'EditDataModal',
  props: ['profileData'],
  components: {
    VuePhoneNumberInput
  },
  data: function() {
    return {
      email: '',
      name: '',
      surname: '',
      phone: '',
      phoneFull: '',
      address: '',
      city: '',
      postCode: '',
      country: '',
      countryId: ''
    }
  },
  watch: {
    profileData: {
      immediate: true,
      handler(newValue) {
        this.email = newValue.email
        this.name = newValue.name
        this.surname = newValue.surname
        this.phone = newValue.phone
        this.address = newValue.address
        this.city = newValue.city
        this.postCode = newValue.post_code

        const country = this.$parent.countryOptions.find(obj => obj.id === newValue.country.id);

        this.country = country
      },
    },
    
  },
  mounted() {
    
  },
  computed: {
    requiredFieldsAreFilled() {
      let fieldsAreFilled;
      if(
        this.name && this.surname  && 
        this.email && this.phoneFull
        && this.address && this.city 
        && this.postCode && this.country
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    }
  },
  methods: {
    phoneDataUpdate(e) {
      this.phoneFull = e.formatInternational;
    },
    submitEditData() {
      let regData = {
        "name": this.name,
        "surname": this.surname,
        "email": this.email,
        "phone": this.phoneFull,
        "address": this.address,
        "city": this.city,
        "post_code": this.postCode,
        "country_id": this.country.id
      }
        this.$emit('submitEditData', regData)
    }
  }
}
</script>