<template>
  <div class="modal register-modal">
    <div class="overlay"  @click="$parent.closePassDataModal"></div>
    <div class="wrapper">
      <img class="close" @click="$parent.closePassDataModal" src="./../img/close.svg"/>
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <div class="form-fields">
              <div class="form-fields-wrapper">
                <div class="title">Edit password</div>
                <div class="label-list">
                  <label class="full">
                    <div class="desc">Current password</div>
                    <input type="text" v-model="curPass"/>
                  </label>
                  <label class="full">
                    <div class="desc">New password</div>
                    <input type="text" v-model="newPass"/>
                  </label>
                  <label class="full">
                    <div class="desc">Confirm password</div>
                    <input type="text" v-model="confirmPass"/>
                  </label>
                </div>
                <div class="button-container">
                  <button class="button color" @click="submitEditPass">
                    <span>Submit</span>
                  </button>
                </div>
                <div v-if="$parent.successMessage" class="desc green">{{$parent.successMessage}}</div>
                <div v-if="$parent.error" class="desc red">{{$parent.error}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditPassModal',
  props: [],
  components: {
  },
  data: function() {
    return {
      curPass: '',
      newPass: '',
      confirmPass: '',
    }
  },
  mounted() {
    
  },
  methods: {
    submitEditPass() {
      let regData = {
        "cur_password": this.curPass,
        "new_password": this.newPass,
        "repeat_password": this.confirmPass,
      }
        this.$emit('submitPassData', regData)
    }
  }
}
</script>