<template>
  <main class="main features-page">
    <div class="section features-section">
    </div>
  </main>
</template>
<script>

export default {
  name: 'Features',
  props: [],
  components: {
  },
  data: function() {
    return {
    }
  },
  methods: {
    setAppAmounts(amounts) {
      this.$emit('setAppAmounts',  amounts)
    },
  }
}
</script>