<template>
  <main class="main profile-page orders-page">
    <div class="section hero-section">
      <video loop autoplay muted>
        <source src="./../videos/hero.mp4" type="video/mp4">
      </video>
      <div class="wrapper">
        <div class="title color">
          <b>Orders</b>
        </div>
      </div>
    </div>
    <div class="section profile-section orders-section">
      <div class="wrapper">
        <div class="toggler">
          <router-link to="/orders/buy" :class="['button pink', {'no-active': this.$route.params.type != 'buy'}]">Buy orders</router-link>
          <router-link to="/orders/sell" :class="['button pink', {'no-active': this.$route.params.type != 'sell'}]">Sell orders</router-link>
        </div>
        <div class="toggler right">
            <button @click="downloadReport" class="button white" type="button">Export report</button>
        </div>
        <div class="table-container">
          <div class="table-wrapper">
            <table v-if="$parent.orderBuyHistory && this.$route.params.type == 'buy'">
              <thead>
                <tr>
                  <td class="desc">Order number</td>
                  <td class="desc">Bank information</td>
                  <td class="desc">Crypto</td>
                  <td class="desc">Fiat</td>
                  <td class="desc">Status</td>
                  <td class="desc">Created</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in $parent.orderBuyHistory" :key="i">
                  <td class="desc">{{item.id}}</td>
                  <td class="desc bold">{{item.payment_type}}</td>
                  <td class="desc bold">{{item.dest_amount}} {{$parent.currency}}</td>
                  <td class="desc bold">{{item.amount}} {{$parent.currency}}</td>
                  <td class="desc status">{{item.status}}</td>
                  <td class="desc">{{formatDate(item.created_at)}}</td>
                </tr>
              </tbody>
            </table>
            <table v-if="$parent.orderSellHistory && this.$route.params.type == 'sell'">
              <thead>
                <tr>
                  <td class="desc">Order number</td>
                  <td class="desc">Crypto</td>
                  <td class="desc">Fiat</td>
                  <td class="desc">Status</td>
                  <td class="desc">Created</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in $parent.orderSellHistory" :key="i">
                  <td class="desc">{{item.id}}</td>
                  <td class="desc bold">{{item.amount_crypto}} {{$parent.currency}}</td>
                  <td class="desc bold">{{item.amount_fiat}} {{$parent.currency}}</td>
                  <td class="desc status">{{item.status}}</td>
                  <td class="desc">{{formatDate(item.created_at)}}</td>
                </tr>
              </tbody>
            </table>
            <div v-if="$parent.orderBuyHistory && !$parent.orderBuyHistory.length && this.$route.params.type == 'buy'" class="desc bold big">No buy orders yet</div>
            <div v-if="$parent.orderSellHistory && !$parent.orderSellHistory.length && this.$route.params.type == 'sell'" class="desc bold big">No sell orders yet</div>
          </div>
        </div>
      </div>
    </div>
    <ReportUnavailableModal v-if="showReportUnavailableModal"/>
  </main>
</template>
<script>

import ReportUnavailableModal from "@/components/ReportUnavailableModal.vue";

export default {
  name: 'MyOrders',
  props: [],
  components: {
      ReportUnavailableModal
  },
  data: function() {
    return {
        showReportUnavailableModal: false
    }
  },
  watch: {
    
  },
  computed: {
   
  },
  methods: {
    setAppAmounts(amounts) {
      this.$emit('setAppAmounts',  amounts)
    },
    formatDate(num) {
      let objectDate = new Date(num * 1000);
      let day = objectDate.getDate();
      if (day < 10) {
          day = '0' + day;
      }
      let month = objectDate.getMonth() + 1;
      if (month < 10) {
          month = `0${month}`;
      }
      let year = objectDate.getFullYear();

      function padTo2Digits(x) {
        return String(x).padStart(2, '0');
      }
      let hoursAndMinutes = padTo2Digits(objectDate.getHours()) + ':' + padTo2Digits(objectDate.getMinutes());
      let format = day + "." + month + "." + year + ' ' + hoursAndMinutes;

      return format
    },
    downloadReport() {
      let reportLink = null;

      if (this.$route.params.type === 'buy') {
        reportLink = this.$parent.buyReport;
      } else {
        reportLink = this.$parent.sellReport;
      }

      if (!reportLink) {
        this.showReportUnavailableModal = true;
        return;
      }

      window.open(reportLink, '_blank');
    },
    closeReportUnavailableModal() {
      this.showReportUnavailableModal = false;
    }
  },
  mounted: function() {
  }
  
}
</script>