<template>
  <main class="main faq-page">
    <div class="section hero-section">
      <video loop autoplay muted>
        <source src="./../videos/hero.mp4" type="video/mp4">
      </video>
      <div class="wrapper">
        <div class="desc">If you have any questions or need assistance, fell free to contact our support team</div>
        <button class="button" @click="$parent.openContactModal">Support</button>
        <div class="desc">Or</div>
      </div>
    </div>
    <div class="section faq-section" ref="faq">
      <div class="wrapper">
        <div class="title color">
          <b>Frequently Asked Questions</b>
        </div>
        <div class="faq-questions">
          <div class="item" v-for="item in $parent.faq" :key="item.id">
            <div class="faq-question" @click="toggleAnswer(item.id)">
              <div :class="['title small', {'active': isActiveAnswer(item.id)}]">
                <b>{{ item.question }}</b>
              </div>
							<img src="./../img/selectArrow.svg" :class="['arrow', {'active': isActiveAnswer(item.id)}]"/>
            </div>
            <div :class="['faq-answer', {'active': isActiveAnswer(item.id)}]">
              <div class="desc" v-html="item.answer" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'Faq',
  props: ['id'],
  components: {
  },
  data: function() {
    return {
      activeId: 0
    }
  },
  watch: {
    id: {
      handler() {
        this.checkId()
      },
    },
  },
  methods: {
    toggleAnswer(id) {
      this.activeId = this.activeId == id ? null : id;
    },
    isActiveAnswer(id) {
      return this.activeId == id;
    },
    checkId() {
      if (this.id) {
        const element = this.$refs.faq;
        if (element) {
          const rect = element.getBoundingClientRect();
          const topPosition = rect.top + window.scrollY;
          window.scrollTo({
            top: topPosition,
            behavior: 'smooth'
          });
        }
        this.toggleAnswer(this.id);
      }
    }
  },
  mounted() {
    this.checkId()
  }
}
</script>