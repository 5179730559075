<template>
  <main class="main home-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <video loop autoplay muted>
          <source src="./../videos/hero.mp4" type="video/mp4">
        </video>
        <div class="wrapper">
          <div class="title color">
            <b>Explore the Cryptosphere</b>
          </div>
          <div class="desc desc-main">Discover the financial realm of the future with Verator.</div>
          <ExchangeCard @setAppAmounts="setAppAmounts" />
          <div class="hero-methods">
            <div class="desc">Pay however you like:</div>
            <div class="list">
              <div class="item">
                <img src="./../img/methods/gpay.svg" class="img"/>
              </div>
              <div class="item">
                <img src="./../img/methods/visa.svg" class="img"/>
              </div>
              <div class="item">
                <img src="./../img/methods/applePay.svg" class="img"/>
              </div>
              <div class="item">
                <img src="./../img/methods/mc.svg" class="img"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section join-us-section">
        <div class="wrapper">
          <div class="list">
            <div class="item">
              <div class="desc">Your new crypto home</div>
              <div class="title">Join us to begin your Crypto journey</div>
            </div>
            <div class="item">
              <div class="title">Instant KYC</div>
              <div class="desc">Everyone on our team strives to broaden your understanding of cryptocurrency potential.</div>
            </div>
            <div class="item">
              <div class="title">Simple for use</div>
              <div class="desc">With Verator, get started and purchase cryptocurrency in minutes.</div>
            </div>
            <div class="item">
              <div class="title">Bank-level
                Safety</div>
              <div class="desc">We are more than a bank. We operate a fully working safe bay.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

import ExchangeCard from "@/components/ExchangeCard.vue";
// import ContactForm from "@/components/ContactForm.vue";
// import ExchangeButton from "@/components/ExchangeButton.vue";

export default {
  name: 'Home',
  props: [],
  components: {
    // ExchangeButton,
    // ContactForm
    ExchangeCard
  },
  data: function() {
    return {
     
    }
  },
  mounted() {
    if (this.$route.query['redirect']) {
      this.$parent.openSignInModal();
    }
    
  },
  methods: {
    setAppAmounts(amounts) {
      this.$emit('setAppAmounts',  amounts)
    },
    checkAuth() {
      if (this.$parent.isAuth) {
        this.$router.push({path: '/buy-crypto'});
      } else {
        this.$parent.openSignInModal();
      }
    }
  }
}
</script>
