<template>
  <main class="main order-details-page">
    <div class="section order-details-section">
      <div class="wrapper">
        <div class="content">
          <div class="card-list">
            <div class="card-list__item order-details">
              <div class="step-list">
                <div class="desc bold big">Step 1. Order details</div>
                <div class="desc bold">Step 2. Payment</div>
                <div class="desc bold">Step 3. Confirmation</div>
              </div>
              <div class="desc">Choose payment method</div>
              <div class="radio-list">
                <label>
                  <select 
                    class="select" 
                    v-if="paymenMethods" 
                    v-model="acivePaymentMethod"
                  >
                    <option v-for="item in paymenMethods" :key="item.id" :value="item">{{ item.title }}</option>
                  </select>
                  <img :src="acivePaymentMethod.image_full" class="img"/>
                </label>
              </div>
              <div class="total">
                <div class="title small">
                  <b>Total amount to pay:</b>
                </div>
                <div class="total-wrapper">
                  <div class="item">
                    <div class="title" :class="{'crypto': !$parent.fiatToCrypto}">{{ leftAmount }} ≈</div>
                  </div>
                  <div class="item">
                    <div class="title" :class="{'crypto': $parent.fiatToCrypto}">{{ rightAmount }}</div>
                  </div>
                </div>
              </div>
              <div class="total-bottom">
                  <div class="text">
                    <div class="desc">Exchange rate</div>
                    <div class="desc">1 {{$parent.takeCurrency.crypto_currency}} = {{$parent.takeCurrency.price}} {{$parent.giveCurrency.symbol}}</div>
                  </div>
                 <div class="text">
                    <div class="desc">Date of order</div>
                    <div class="desc">{{currentDate}}</div>
                  </div>
              </div>
            </div>
            <div class="card-list__item-container">
              <div class="card-list__item your-card">
                <div class="title small title-main">Your Cart</div>
                <div class="row row-up">
                  <div class="desc bold">{{ rightAmount }}</div>
                  <div class="title">{{ leftAmount }}</div>
                  <router-link to="/buy-crypto" class="button white">Change amount</router-link>
                </div>
                <div class="table">
                  <div class="title small">
                    <b>Order summary</b>
                  </div>
                  <div class="row" v-if="!paymentInfo">
                    <div class="desc">Exchange rate</div>
                    <div class="desc">1 {{$parent.takeCurrency.crypto_currency}} = {{$parent.takeCurrency.price}} {{$parent.giveCurrency.symbol}}</div>
                  </div>
                  <div class="row" v-if="paymentInfo && paymentInfo.asset && paymentInfo.currency_code">
                    <div class="desc">Exchange rate</div>
                    <div class="desc">1 {{paymentInfo.asset.crypto_currency}} = {{paymentInfo.fiatAmount}} {{paymentInfo.currency_code.symbol}}</div>
                  </div>
                  <div class="row">
                    <div class="desc">{{ $parent.fiatToCrypto ? 'Coins' : 'Money' }} to receive</div>
                    <div class="desc">{{ rightAmount }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="button-container">
            <router-link to="/buy-crypto" class="button bordered">
              <span>Cancel order</span>
            </router-link>
            <button class="button color" @click="submit">
              <span>Continue</span>
            </button>
          </div>
          <div v-if="error" class="desc red error">{{ error }}</div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

import fiatSection from "@/components/FiatSection.vue";

export default {
  name: 'OrderDetails',
  props: ['paymenMethods', 'profileData'],
  components: {
  },
  data: function() {
    return {
      paymentMethod: 'card',
      wallet: '',
      hours: 1,
      minutes: 0,
      seconds: 0,
      timer: null,
      currentDate: '',
      submitSuccess: false,
      acivePaymentMethod: {},
      recipientName: '',
      beneficiaryBankName: '',
      beneficiaryBankAddress: '',
      iban: '',
      walletAddress: '',
      walletStatus: 'denied',
      error: '',
      paymentInfo: {},
      orderApproved: false,
      terms:'',
      walletAsset: ''
    }
  },
  watch: {
    paymenMethods: {
      immediate: true,
      handler(newValue) {
        if(newValue && newValue[0]) {
         this.acivePaymentMethod = newValue[0]
        }
      },
    },
    profileData: {
      immediate: true,
      handler(newValue) {
        this.recipientName = newValue.name + ' ' + newValue.surname 
      },
    },
    
  },
  
  mounted: function() {
    if (this.$parent.paymentMethod) {
      this.paymentMethod = this.$parent.paymentMethod
    }
    // if (!this.$parent.giveAmount && this.$route.params.type == 'buy') {
    //   this.$router.push({path: '/buy-crypto'})
    // } else if(!this.$parent.giveAmount && this.$route.params.type == 'sell') {
    //   this.$emit('setFiatToCrypto', false)
    //   if (localStorage.getItem('paymentInfo')) {
    //     this.getWalletStatus();
    //   } else {
    //     this.$router.push({path: '/sell-crypto'})
    //   }
    // } else if (this.$parent.giveAmount && this.$route.params.type == 'sell') {
    //   this.$emit('setFiatToCrypto', false)
    //   this.getWalletStatus();
    // }
    
    if (!this.$parent.giveAmount ) {
      this.$router.push({path: '/buy-crypto'});
    }


    this.$parent.changeCheckoutSuccess(false);
    this.startTimer();
    this.getCurrentDate();
  },
  methods: {
    approveOrder() {
      this.orderApproved = true;
      localStorage.setItem('paymentInfo', '');
    },
    verifyWallet() {
      let wallet = {
        wallet: this.walletAddress,
        asset: this.$parent.takeCurrency.title
      }
      this.$http.post(process.env.VUE_APP_API + 'sell/sell_wallet_verification', wallet)
      .then((res) => {
        if (res.data.status == 'OK') {
          this.error = '';
          this.getWalletStatus();
        }
      })
      .catch((res) => {
        if (res.response.data.errors) {
          const firstErrors = {};
          for (const key in res.response.data.errors) {
            if (res.response.data.errors.hasOwnProperty(key) && Array.isArray(res.response.data.errors[key]) && res.response.data.errors[key].length > 0) {
                firstErrors[key] = res.response.data.errors[key][0];
                this.error = firstErrors[key];
            }
          }
        } else {
          this.error = res.response.data.message;
        }
      }) 
    },
    sellCrypto() {
      let storage = {
        'amount': this.$parent.takeAmount,
        'wallet': this.walletAddress,
        'asset': this.$parent.takeCurrency,
        'currency_code': this.$parent.giveCurrency,
        'bank_account': this.iban,
        'bank_name': this.beneficiaryBankName,
        'bank_address': this.beneficiaryBankAddress,
        'first_name': this.profileData.name,
        'last_name': this.profileData.surname,
        'address': this.profileData.address,
        'fiatAmount': this.$parent.giveAmount
      }

      let data = {
        'amount': this.$parent.takeAmount,
        'wallet': this.walletAddress,
        'asset': this.$parent.takeCurrency.crypto_currency,
        'currency_code': this.$parent.giveCurrency.title == 'Euro' ? 'EUR' : '',
        'bank_account': this.iban,
        'bank_name': this.beneficiaryBankName,
        'bank_address': this.beneficiaryBankAddress,
        'first_name': this.profileData.name,
        'last_name': this.profileData.surname,
        'address': this.profileData.address,
      }
      this.$http.post(process.env.VUE_APP_API + 'sell/create', data)
      .then(() => {
        this.paymentInfo = storage;
        localStorage.setItem('paymentInfo', JSON.stringify(storage));
      })
      .catch((res) => {
        if (res.response.data.errors) {
          const firstErrors = {};
          for (const key in res.response.data.errors) {
            if (res.response.data.errors.hasOwnProperty(key) && Array.isArray(res.response.data.errors[key]) && res.response.data.errors[key].length > 0) {
                firstErrors[key] = res.response.data.errors[key][0];
                this.error = firstErrors[key];
            }
          }
        } else {
          this.error = res.response.data.message;
        }
      })
    },
    getWalletStatus() {
      this.$http.get(process.env.VUE_APP_API + 'sell/sell_wallet_verification_status?asset=' + this.$parent.takeCurrency.crypto_currency)
      .then((res) => {
        this.walletStatus = res.data.order.status;
        this.walletAddress = res.data.order.wallet;
        this.walletAsset = res.data.order.asset;
        if (this.walletStatus == 'approved' && localStorage.getItem('paymentInfo')) {
          this.paymentInfo =  JSON.parse(localStorage.getItem('paymentInfo'));
        }
      })
      .catch(() => {
      })
    },
    getCurrentDate() {
      const today = new Date();
      const formattedDate = today.toISOString().split('T')[0];

      this.currentDate = formattedDate;
    },
   startTimer() {
      this.timer = setInterval(() => {
        if (this.hours === 0 && this.minutes === 0 && this.seconds === 0) {
          clearInterval(this.timer);
        } else {
          if (this.seconds > 0) {
            this.seconds--;
          } else {
            if (this.minutes > 0) {
              this.minutes--;
              this.seconds = 59;
            } else {
              this.hours--;
              this.minutes = 59;
              this.seconds = 59;
            }
          }
        }
      }, 1000);
    },
    submit() {
      if (this.$parent.isAuth) {
        this.$parent.openPersonalInfoModal();
        this.$emit('setWallet', this.wallet);
        this.$emit('setPaymentMethod', this.paymentMethod);
      } else {
        this.$parent.openSignInModal();
      }
    }
  },
  computed: {
    fiatSection() {
      return fiatSection
    },
    leftAmount()  {
      if (localStorage.getItem('paymentInfo')) {
        return JSON.parse(localStorage.getItem('paymentInfo')).amount + ' ' +  JSON.parse(localStorage.getItem('paymentInfo')).asset.crypto_currency;
      } else {
        const parent = this.$parent;
        return parent.fiatToCrypto ? `${parent.giveCurrency.symbol} ${parseFloat(parent.giveAmount ).toFixed(2)}` : `${parent.takeAmount} ${parent.takeCurrency.crypto_currency}`;
      }
    },
    rightAmount() {
      if (localStorage.getItem('paymentInfo')) {
        return JSON.parse(localStorage.getItem('paymentInfo')).currency_code.symbol + ' ' + JSON.parse(localStorage.getItem('paymentInfo')).fiatAmount;
      } else {
        const parent = this.$parent;
        return parent.fiatToCrypto ? `${parent.takeAmount} ${parent.takeCurrency.crypto_currency}` : `${parent.giveCurrency.symbol} ${parseFloat(parent.giveAmount ).toFixed(2)}`;
      }
    }
  }
}
</script>