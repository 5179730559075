<template>
  <main class="main exchange-page">
    <div class="section exchange-section">
    </div>
  </main>
</template>
<script>

export default {
  name: 'ExchangeCrypto',
  props: [],
  components: {
  },
  data: function() {
    return {
    }
  },
  watch: {
    
  },
  computed: {
   
  },
  methods: {
    setAppAmounts(amounts) {
      this.$emit('setAppAmounts',  amounts)
    },
  },
  mounted: function() {
  }
  
}
</script>