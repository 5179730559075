<template>
  <main class="main exchange-page">
    <div class="section hero-section">
      <video loop autoplay muted>
        <source src="./../videos/hero.mp4" type="video/mp4">
      </video>
      <div class="wrapper">
        <ExchangeCard @setAppAmounts="setAppAmounts" :type="'buy'" />
        <div class="desc">
          By continuing, you agree to our cookie policy.
        </div>
        <div class="hero-methods">
          <div class="desc">Pay however you like:</div>
          <div class="list">
            <div class="item">
              <img src="./../img/methods/gpay.svg" class="img"/>
            </div>
            <div class="item">
              <img src="./../img/methods/visa.svg" class="img"/>
            </div>
            <div class="item">
              <img src="./../img/methods/applePay.svg" class="img"/>
            </div>
            <div class="item">
              <img src="./../img/methods/mc.svg" class="img"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import ExchangeCard from "@/components/ExchangeCard.vue";
import router from "@/router";

export default {
  name: 'BuyCrypto',
  props: ['currencies', 'crypto', 'rates'],
  components: {
  
    ExchangeCard
  },
  data: function() {
    return {
      giveCurrency: {},
      takeCurrency: {},
      giveAmount: 100,
      takeAmount: 0,
      paymentMethod: 'card',
      fiatToCrypto: true
    }
  },
  watch: {
    currencies: {
      immediate: true,
      handler() {
        if(Object.values(this.filteredCurrencies)[0]) {
          this.giveCurrency = Object.values(this.filteredCurrencies)[0];
        }
      },
    },
    takeCurrency: {
      immediate: true,
      handler() {
        this.changeTakeAmount(this.giveAmount);
      },
    },
    rates: {
      immediate: true,
      handler(newValue) {
        if(newValue[0]) {
          this.takeCurrency = newValue[0];
        }
      },
    }
  },
  computed: {
    filteredRatesEur() {
      let rates = this.$parent.rates.filter(item => item.currency === 'EUR');

      if (!this.fiatToCrypto) {
        const parent = this.$parent;
        const wallets = parent.wallets && parent.wallets.map((currency) => currency.toUpperCase());
        rates = rates.filter(item => wallets && wallets.includes(item.crypto_currency.toUpperCase()))
      }

      return rates;
    },
    filteredRatesUsd() {
      let rates = this.$parent.rates.filter(item => item.currency === 'USD');

      if (!this.fiatToCrypto) {
        const parent = this.$parent;
        const wallets = parent.wallets && parent.wallets.map((currency) => currency.toUpperCase());
        rates = rates.filter(item => wallets && wallets.includes(item.crypto_currency.toUpperCase()))
      }

      return rates;
    },
    filteredCurrencies() {
      if (this.fiatToCrypto) {
        return Object.fromEntries(Object.entries(this.$parent.currencies).filter(([,data]) => data.sale));
      } else {
        return Object.fromEntries(Object.entries(this.$parent.currencies).filter(([,data]) => data.buy));
      }
    }
  },
  methods: {
    setAppAmounts(amounts) {
      this.$emit('setAppAmounts',  amounts)
    },
    updatePaymentMethod(method) {
      this.paymentMethod = method;
    },
    changeTakeAmount(newValue) {
      if (this.takeCurrency.price) {
        this.takeAmount = newValue / this.takeCurrency.price;
      } else {
        this.takeAmount = 0;
      }
    },
    changeGiveAmount(newValue) {
      if (this.takeCurrency.price) {
        this.giveAmount = this.takeCurrency.price * newValue;
      } else {
        this.giveAmount = 0;
      }
    },
    defaultTakeCurrency() {
      if (this.giveCurrency.title == 'Euro') {
        this.takeCurrency = this.filteredRatesEur[0];
      }
      if (this.giveCurrency.title == 'US dollar') {
        this.takeCurrency = this.filteredRatesUsd[0]
      }
    },
    submit() {
      const fiatAmount = this.giveAmount;

      if (!this.$parent.isEmailVerified()) {
        router.push('/email/verify/notice');
        return;
      }

      if (this.fiatToCrypto && this.$parent.minOrderPriceFiatToCrypto && fiatAmount < this.$parent.minOrderPriceFiatToCrypto) {
        this.$toast(`Min order price is ${this.$parent.minOrderPriceFiatToCrypto} EUR.`, {type: 'error'});
        return;
      } else if(!this.fiatToCrypto && this.$parent.minOrderPriceCryptoToFiat && fiatAmount < this.$parent.minOrderPriceCryptoToFiat) {
        this.$toast(`Min order price is ${this.$parent.minOrderPriceCryptoToFiat} EUR.`, {type: 'error'});
        return;
      }

      this.$emit('setCheckoutData', this.giveAmount, this.takeAmount, this.takeCurrency, this.giveCurrency, this.paymentMethod, this.fiatToCrypto)
      if (this.fiatToCrypto) {
        this.$router.push({path: '/order-details/buy'});
      } else {
        this.$router.push({path: '/order-details/sell'});
      }
      
    },
    toggleFiatToCrypto() {
      this.fiatToCrypto = !this.fiatToCrypto;
      this.paymentMethod = this.fiatToCrypto ? 'card' : 'crypto';
      let currencies = Object.entries(this.filteredCurrencies);

      if (!currencies.some(([,data]) => data.title === this.giveCurrency.title)) {
        this.giveCurrency = currencies[0][1];
        this.defaultTakeCurrency();
      }

      if (!this.fiatToCrypto) {
        if (this.giveCurrency.title === 'Euro') {
          if (this.filteredRatesEur && this.filteredRatesEur.length) {
            this.takeCurrency = this.filteredRatesEur[0];
          }
        } else {
          if (this.filteredRatesUsd && this.filteredRatesUsd.length) {
            this.takeCurrency = this.filteredRatesUsd[0];
          }
        }
      }
    }
  },
  mounted: function() {
    if (this.$parent.giveAmount) {
      this.giveAmount = this.$parent.giveAmount;
      this.takeAmount = this.$parent.takeAmount;
      this.takeCurrency = this.$parent.takeCurrency;
      this.giveCurrency = Object.values(this.filteredCurrencies)[0];
    }
  }
  
}
</script>