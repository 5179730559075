<template>
    <div class="cookie-modal" v-if="!showCookieModal">
      <div class="cookie-content">
        <span v-if="manageIsVisible">
          <div class="chekbox-container">
            <label class="chekbox-label">
              <div class="chekbox-label-wrapper">
                <input type="checkbox" disabled v-model="essential"/>
                <div class="checkbox"></div>
                <div class="title small">Essential Cookies</div>
                <div class="desc">This cookie maintains a unique session for each user, which is crucial for functional navigation and user interaction across the site.</div>
              </div>
            </label>
          </div>
          <div class="chekbox-container">
            <label class="chekbox-label">
              <div class="chekbox-label-wrapper">
                <input type="checkbox" v-model="optional"/>
                <div class="checkbox"></div>
                <div class="title small">Optional Cookies</div>
                <div class="desc">
                  This cookie is used by Google Analytics to identify unique users, track visitor behavior, and gather data for analytics reports.
                </div>
              </div>
            </label>
          </div>
          <div class="chekbox-container">
            <label class="chekbox-label">
              <div class="chekbox-label-wrapper">
                <input type="checkbox" v-model="google"/>
                <div class="checkbox"></div>
                <div class="desc">
                  This cookie, also used by Google Analytics, tracks user behavior and interactions, enabling detailed analytics for specific sections of the website. 
                </div>
              </div>
            </label>
          </div>
          
          <button class="button" @click="acceptCookies">Accept all</button>
          <button @click="acceptCookies" class="button">Accept selected</button>
          <button @click="declineCookies" class="button gray manage-button">Decline</button>
        </span>
        <span v-else>
          <p class="desc" v-if="$parent.textPageList && $parent.textPageList.length">We use cookies on our website to enhance your experience. Before you proceed with using our Website, please get acquainted with the way we handle a User’s data in our <a @click="$parent.goToPrivacy()" class="desc link">{{ $parent.textPageList.filter(item => item.is_privacy)[0].title }}</a> and the use of Cookies in the <a @click="$parent.goToPage( $parent.textPageList.filter(item => item.title == 'Cookie Policy')[0] )" class="desc link">{{$parent.textPageList.filter(item => item.title == 'Cookie Policy')[0].title}}</a>.</p>
          <button class="button" @click="acceptCookies">Accept</button>
          <button @click="declineCookies" class="button gray decline-button">Decline</button>
          <button @click="manageCookies" class="button gray manage-button">Manage cookies</button>
        </span>
      </div>
    </div>
  </template>
  
  <script>
  import VueCookies from 'vue-js-cookie';
  
  export default {
    data() {
      return {
        showCookieModal: VueCookies.get('cookiesAccepted'), // Check if user accepted cookies
        manageIsVisible: false,
        essential: true,
        google: false,
        optional: false
      };
    },
    methods: {
      acceptCookies() {
        this.showCookieModal = true;
        VueCookies.set('cookiesAccepted', 'true', 365); // Set a cookie that lasts for a year
      },
      declineCookies() {
        this.showCookieModal = true;
        VueCookies.set('cookiesAccepted', 'false', 365);
      },
      manageCookies() {
        this.manageIsVisible = true;
      }
    },
  };
  </script>
  <style scoped>
  .cookie-modal {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 400px;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    z-index: 3;
  }
  .cookie-modal .desc {
    color: #000;
  }
  .button {
    margin: 16px 16px 0;
    width: 100px;
    display: inline-block;
    color: #000;
  }
  .cookie-content {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .cookie-content .button {
    width: auto;
  }
  .cookie-content .chekbox-label .title.small {
    color: #000;
    font-size: 18px;
    line-height: 24px;
  }
  .cookie-content .chekbox-container + .chekbox-container {
    margin-top: 16px;
  }
  .accept-button {
    background-color: #007BFF;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .accept-button:hover {
    background-color: #0056b3;
  }
  </style>