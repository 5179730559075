<template>
  <div class="modal contact-modal">
    <div class="overlay"  @click="$parent.closeContactModal"></div>
    <div class="wrapper">
      <img class="close" @click="$parent.closeContactModal" src="./../img/close.svg"/>
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <div class="form-fields">
              <div class="form-fields-wrapper">
                <div class="form-wrapper">
                  <div class="title color">
                    <b>Support</b>
                  </div>
                  <form @submit.prevent="submit" class="contact-form" :class="{submitted: formSubmitted}">
                    <template v-if="!formSubmitted">
                      <div class="contact-form-container">
                        <div class="column">
                          <label>Name</label>
                          <input required v-model="name" type="text">
                        </div>
                        <div class="column">
                          <label>Surname</label>
                          <input v-model="surname" type="text">
                        </div>
                        <div class="column">
                          <label>E-Mail Address</label>
                          <input required v-model="email" type="email">
                        </div>
                        <div class="column" v-if="$parent.countryOptionsIso && $parent.countryOptionsIso.length">
                          <label>Phone </label>
                          <VuePhoneNumberInput v-model="phone" :only-countries="$parent.countryOptionsIso" :fetch-country="true" @update="phoneDataUpdate($event)"/>
                        </div>
                        <div class="column full">
                          <label>Topic</label>
                          <input v-model="topic" type="text">
                        </div>
                        <div class="column full">
                          <label>Message</label>
                          <textarea v-model="message" required name="message" cols="3" rows="5"></textarea>
                        </div>
                      </div>
                      <div class="bottom">
                        <button class="button color" type="submit">
                          <span>Send</span>
                        </button>
                      </div>
                      <div v-if="error" class="desc red">{{error}}</div>
                    </template>
                    <div v-else class="success-message">
                      <div class="desc green">Your application successfully submitted!</div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
Vue.component('vue-phone-number-input', VuePhoneNumberInput);

export default {
  components: {
    VuePhoneNumberInput
  },
  data: function () {
    return {
      error: '',
      formSubmitted: false,
      name: '',
      surname: '',
      email: '',
      phone: '',
      industry: '',
      website: '',
      volume: '',
      country: '',
      countryId: '',
      comment: '',
      topic: '',
      message: '',
      countryOptionsId: [],
      phoneFull: ""
    }
  },
  methods: {
    selectIndustry(event) {
      this.industry = event.text;
    },
    selectVolume(event) {
      this.volume = event.text;
    },
    selectCountry(event) {
      this.country = event.text;
      for (let i = 0; i < this.$parent.countryOptions.length; i++) {
        if (this.$parent.countryOptions[i] == event.text) {
          this.countryId = this.countryOptionsId[i];
        }
      }
    },
    phoneDataUpdate(e) {
      this.phoneFull = e.formatInternational;
    },
    submit() {
      this.$http.post(process.env.VUE_APP_API + 'contact-us-ex', {
        first_name: this.name,
        last_name: this.surname,
        email: this.email,
        phone: this.phoneFull,
        topic: this.topic,
        message: this.message
      })
      .then(({data}) => {
        if (data.status === 'OK') {
          this.formSubmitted = true
        }
      })
      .catch((res) => {
        if (res.response.data.errors) {
          const firstErrors = {};
          for (const key in res.response.data.errors) {
            if (res.response.data.errors.hasOwnProperty(key) && Array.isArray(res.response.data.errors[key]) && res.response.data.errors[key].length > 0) {
                firstErrors[key] = res.response.data.errors[key][0];
                this.error = firstErrors[key];
            }
          }
        } else {
          this.error = res.response.data.message;
        }
      })
    }
  }
}
</script>

<style scoped>

</style>