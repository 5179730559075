<template>
	<header class="header">
      <div class="wrapper">
        <div class="left">
			<router-link to="/" class="logo">
                <img src="./../img/logo.svg" class="img"/>
            </router-link>
		</div>
		<div class="center">
			<ul class="nav">
				<template  v-for="item in $parent.headerNav1">
					<li v-if="item.path" class="nav__item" :key="item.title">
						<router-link class="desc" :to="item.path">
							<b>{{item.title}}</b>
						</router-link>
					</li>
				</template>
			</ul>
		</div>
		<div class="right">
			<ul class="nav">
				<template  v-for="item in $parent.headerNav2">
					<li v-if="item.path" class="nav__item" :key="item.title">
						<router-link class="desc" :to="item.path">
							<img v-if="item.title == 'Learn'" src="./../img/learn.svg" class="img"/>
							{{item.title}}
						</router-link>
					</li>
					<li v-if="item.modal == 'contact'" class="nav__item" :key="item.title">
						<div class="desc hover-link" @click="$parent.openContactModal">
							<img v-if="item.title == 'Help'" src="./../img/help.svg" class="img"/>
							{{item.title}}
						</div>
					</li>
				</template>
			</ul>
			<div class="buttons">
				<button v-if="!$parent.isAuth" class="button" @click="$parent.openSignInModal">Sign in</button>
				<a v-if="!$parent.isAuth" class="desc link" @click="$parent.openRegister">Sign up</a>
				<div v-if="$parent.isAuth" class="user-link">
					<div class="user">
						<img src="./../img/user.svg" class="img"/>
					</div>
					<div class="text" v-if="$parent.profileData.name">
						<div class="desc bold">{{ $parent.profileData.name }}</div>
						<div class="desc desc-status">{{ $parent.profileData.status == 'approved' ? 'Approved' : ' Not Approved' }}</div>
					</div>
					<div class="dropdown">
						<div class="dropdown-nav">
							<div class="nav__item">
								<router-link class="desc" to="/profile">Profile</router-link>
							</div>
							<div class="nav__item">
								<router-link class="desc" to="/orders/buy">Orders</router-link>
							</div>
							<div class="nav__item">
								<a class="desc" @click="$parent.logout">Log out</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
      </div>
  </header>
</template>

<script>

export default {
	name: 'Header',
  props: [],
	data: function() {
		return {
		}
	},
	methods: {
    
	}
}
</script>
